/**
 *
 * @param {Ref} store
 * @param {string} loadingId
 */
export function isLoading(store, loadingId) {
  const { loading } = store.current;
  return loading && loading[loadingId];
}

/**
 *
 * @param {Store} store
 * @param {string} loadingId
 * @param {boolean} loadingValue
 */
export function setLoading(store, loadingId, loadingValue) {
  store.setState({
    loading: {
      ...store.current.loading,
      [loadingId]: Boolean(loadingValue),
    },
  });
}
