import { applicationConsumer } from '../applicationConsumer.js';
import { setLoading } from '../loading.js';

export function initialize(store) {
  applicationConsumer.subscriptions.create(
    { channel: 'JobStatusChannel' },
    {
      received: (data) => {
        const { status, name } = data;
        const loadingId = `job:${name}`;
        if (status === 'started') {
          setLoading(store, loadingId, true);
        } else if (status === 'completed') {
          setLoading(store, loadingId, false);
        }
      },
    }
  );
}
