import { Controller } from '@hotwired/stimulus';
import { applicationStore, isLoading, initialize } from '../store/index.js';

export default class extends Controller {
  connect() {
    initialize(applicationStore);
    applicationStore.subscribe(this.onStoreChange);
  }

  // eslint-disable-next-line class-methods-use-this
  launchJob = async (event) => {
    const response = await fetch(event.params.url);
    await response.text();
  };

  onStoreChange = (previousState, nextState) => {
    if (
      this.didStopLoading(previousState, nextState, 'job:DraftOrdersDownloadJob') ||
      this.didStopLoading(previousState, nextState, 'job:OrdersDownloadJob')
    ) {
      document.location.reload();
    }
  };

  // eslint-disable-next-line class-methods-use-this
  didStopLoading = (previousState, nextState, job) => isLoading(previousState, job) && !isLoading(nextState, job);
}
