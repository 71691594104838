import Rails from '@rails/ujs';
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  next(e) {
    this.focusNextInput(e.currentTarget);
  }

  nextAndSubmit(e) {
    this.focusNextInput(e.currentTarget);
    // e.currentTarget.form.submit();
    Rails.fire(e.currentTarget.form, 'submit');
  }

  focusNextInput(elem) {
    const tidx = 1 + Number.parseInt(elem.getAttribute('tabindex'), 10);

    const nextElem = this.element.querySelector(`[tabindex="${tidx}"]`);

    if (nextElem) {
      nextElem.focus();
    }
  }
}
