import { Controller } from '@hotwired/stimulus';
import { applicationStore, isLoading } from '../store/index.js';

/**
 * @example
 * <
 *    data-controller="action-button"
 *    data-loading="monJob"
 * />
 *
 * => applicationStore.current.loading.monJob sera utilisé pour l'état du bouton
 */
export default class extends Controller {
  static targets = [];

  connect() {
    applicationStore.subscribe(this.render);
    this.render();
  }

  disconnect() {
    applicationStore.unsubscribe(this.render);
  }

  render = () => {
    const { element } = this;
    const { loading } = element.dataset;
    if (loading == null) {
      // eslint-disable-next-line no-console
      console.warn('data-loading is not defined');
    }
    const value = isLoading(applicationStore, loading);

    element.setAttribute('aria-busy', String(value));
  };
}
